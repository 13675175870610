
import { defineComponent, PropType, ref, Ref, watch } from "vue";
import { ExclamationCircleFilled } from "@ant-design/icons-vue";
import { useI18n } from "vue-i18n";
import { Visit } from "@/types";

export const PaymentInfoComponent = defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    visit: {
      type: Object as PropType<Visit>,
      required: true,
    },
  },
  components: {
    ExclamationCircleFilled,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t, n } = useI18n();
    const visible: Ref<boolean> = ref(false);

    const cancel = () => {
      emit("update:modelValue", false);
    };

    watch(
      () => props.modelValue,
      (nv) => {
        visible.value = nv;
      }
    );

    return {
      cancel,
      visible,
      n,
      t,
    };
  },
});
export default PaymentInfoComponent;
