
import { defineComponent, inject, onMounted, ref, Ref, onUnmounted } from "vue";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { useI18n } from "vue-i18n";
import PaymentInfo from "../components/PaymentInfo.vue";
import { useRoute, useRouter } from "vue-router";
import { useAurero } from "../composable/useAurero";
import { Visit, ProductCode, NfzStatus } from "@/types";
import { AxiosStatic } from "axios";

export const VisitDetailsComponent = defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    ArrowLeftOutlined,
    PaymentInfo,
    CloseOutlined,
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const url: Ref<string> = ref("");
    const visit = ref<Visit>();
    const { getVisitUrl } = useAurero();
    const http = inject("http") as AxiosStatic;

    const loading: Ref<boolean> = ref(true);
    const showPaymentModal: Ref<boolean> = ref(false);
    const showNfzModal = ref(false);
    const nfzStatus: Ref<NfzStatus> = ref({
      status: "",
      confirmedByPatient: false,
    });

    const isInsuranceOnButtonDisabled = ref(false);
    const nfzCheckStatusAtteptCount = ref(0);

    const nfzProductCode = ref("");

    let intervalHandle: NodeJS.Timer;

    const nfzProductCodeOptions: Ref<Array<ProductCode>> = ref([]);

    const goBack = () => {
      router.push(
        `${(route.query.redirectUrl ?? "/").toString()}?page=${
          route.query.page
        }&pageSize=${route.query.pageSize}${
          route.query.choosedVisitId
            ? `&choosedVisitId=${route.query.choosedVisitId}`
            : ""
        }`
      );
    };

    const changeNfzProductCode = async (value: string) => {
      await http.put(
        `v1/visits/${route.query.choosedVisitId}/change-nfz-code`,
        value,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );
    };

    const getNfzProductCodes = async () => {
      nfzProductCodeOptions.value = await http
        .get(`v1/visits/product-codes`)
        .then((res) => res.data);
    };

    const getNfzStatus = async () => {
      try {
        nfzStatus.value = await http
          .get(`v1/visits/${route.query.choosedVisitId}/patient-nfz`)
          .then((res) => res.data);
      } catch {
        nfzStatus.value.status = "UNKNOWN";
      } finally {
        if (nfzStatus.value.confirmedByPatient) {
          showNfzModal.value = false;
          await getNfzProductCodes();
        } else {
          if (nfzStatus.value.status == "UNKNOWN") {
            showNfzModal.value = true;
            isInsuranceOnButtonDisabled.value = true;
            if (nfzCheckStatusAtteptCount.value < 2) {
              intervalHandle = setTimeout(() => {
                nfzCheckStatusAtteptCount.value++;
                getNfzStatus();
              }, 5000);
            } else {
              isInsuranceOnButtonDisabled.value = false;
              await getNfzProductCodes();
            }
          } else if (nfzStatus.value.status == "UNINSURED") {
            await getNfzProductCodes();
            showNfzModal.value = true;
          } else {
            await getNfzProductCodes();
            showNfzModal.value = false;
          }
        }
      }
    };

    const changeInsuraceStatus = async () => {
      showNfzModal.value = false;
      await http.post(`v1/visits/${route.query.choosedVisitId}/confirm/nfz`);
    };

    onMounted(async () => {
      try {
        url.value = await getVisitUrl(props.id);
        visit.value = await http
          .get(`v1/visits/${route.query.choosedVisitId}`)
          .then((res) => res.data);
      } finally {
        nfzProductCode.value = visit.value?.details?.nfzProductCode
          ? visit.value?.details?.nfzProductCode
          : "";
        showPaymentModal.value =
          route.query.paymentType === "CASH" ? true : false;
        loading.value = false;
        if (
          visit.value?.details?.nfzVisit == true &&
          route.query.redirectUrl == "/"
        ) {
          getNfzStatus();
        }
      }
    });

    onUnmounted(() => {
      clearInterval(intervalHandle);
    });

    return {
      goBack,
      visit,
      loading,
      showPaymentModal,
      url,
      nfzProductCode,
      nfzProductCodeOptions,
      showNfzModal,
      nfzStatus,
      changeNfzProductCode,
      changeInsuraceStatus,
      isInsuranceOnButtonDisabled,
      nfzCheckStatusAtteptCount,
      t,
    };
  },
});
export default VisitDetailsComponent;
