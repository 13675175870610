import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65cc6858"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "payment-info__title" }
const _hoisted_2 = { class: "payment-info__price" }
const _hoisted_3 = { class: "payment-info__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationCircleFilled = _resolveComponent("ExclamationCircleFilled")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    class: "payment-info",
    width: "650px",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    closable: false
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_a_button, {
        class: "payment-info__cancel",
        type: "tertiary",
        shape: "round",
        onClick: _ctx.cancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("PAYMENT_INFO.CLOSE")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ExclamationCircleFilled),
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.t("PAYMENT_INFO.TITLE")), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.n(_ctx.visit?.payment.patientPrice ?? 0, "currency", "pl-PL")), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t("PAYMENT_INFO.DESC")), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}